.footer {
    border-top: 2px solid rgba(102, 102, 102, 0.5);
    padding: 32px 0;

    display: flex;
    grid-column-gap: 24px;
    justify-content: center;
}

#copyright {
    flex: 1;
    text-align: right;
}

#imprint {
    flex: 1;
}