.contact {
    display: flex;
}

#contactHeading {
    white-space: nowrap;
}

#contactMessage {
    font-size: 1.3rem;
}

@media screen and (min-width: 900px) {
    .contact {
        align-items: center;
        column-gap: 5vw;
        justify-content: left;
    }
}

@media screen and (max-width: 900px) {
    .contact {
        flex-direction: column;
    }
}