.timelineDate {
    font-size: 1.5rem;
}

.timelineContent {
    background-color: #27272a;
    border-radius: 18px;
    box-shadow: 0 0 0 3px #6666667F inset;
    padding: 1px 18px 18px;
}

.timelineContentWrapper {
    text-decoration: none !important;
    color: white !important;
}

.timelineContent:hover {
    box-shadow: 0 0 0 3px #8A5CF5 inset;
    transform: scale(1.01);
    transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;
}

@media (prefers-color-scheme: light) {
    .timelineContentWrapper {
        color: black !important;
    }

    .timelineContent {
        background-color: #f8f8f8;
    }
}