.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

a {
    color: white;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

#content {
    flex: 1;
}

@media screen and (min-width: 900px) {
    #content {
        padding: 32px 15vw;
    }
}

@media screen and (max-width: 900px) {
    #content {
        padding: 8px 5vw;
    }
}

@media (prefers-color-scheme: light) {
    a {
        color: black;
    }
}