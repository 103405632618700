.home {
    display: flex;
    flex-direction: column;
    padding: 32px 0;
}

@media screen and (min-width: 900px) {
    .home {
        grid-row-gap: 80px;
    }
}

@media screen and (max-width: 900px) {
    .home {
        grid-row-gap: 32px;
    }
}

.home a {
    color: #5352ed;
}

.home a:hover {
    text-decoration: underline;
}

.headingFontSize {
    font-size: 2.5rem;
}

.contentFontSize {
    font-size: 1.2rem;
}