.projects {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 16px;
}

@media screen and (min-width: 900px) {
    .projects {
        flex-direction: row;
        grid-column-gap: 5vw;
    }
}

@media screen and (max-width: 900px) {
    .projects {
        flex-direction: column;
        row-gap: 5vw;
    }
}