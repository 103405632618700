.project {
    flex: 1;
}

.project a {
    color: #ffffff;
}

.projectContent {
    background-color: #27272a;
    border-radius: 25px;
    box-shadow: 0 0 0 3px #6666667F inset;
    height: 512px;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 0 32px;
}

.projectTitle {
    padding-top: 20%;
    display: flex;
    height: 20%;
    align-items: center;
    justify-content: center;
}

.project a:hover {
    text-decoration: none;
}

.projectContent:hover {
    box-shadow: 0 0 0 3px #8A5CF5 inset;
    transform: scale(1.01);
    transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.projectDescription {
    flex: 1;
}

.repoInfo {
    display: flex;
    grid-column-gap: 10%;
    justify-content: center;
    padding-bottom: 15%;
}

.repoIcon {
    fill: white;
}

@media (prefers-color-scheme: light) {
    .projectContent {
        background-color: #f8f8f8;
    }

    .repoIcon {
        fill: black;
    }

    .project a {
        color: #000000;
    }
}