body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #17171a;
    color: white;
}

@media (prefers-color-scheme: light) {
    body {
        background-color: white;
        color: black;
    }
}