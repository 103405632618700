@keyframes wiggle {
    0% { transform: rotate(0deg); }
    10% { transform: rotate(-5deg); }
    30% { transform: rotate(5deg); }
    40% { transform: rotate(0deg); }
    100% { transform: rotate(0deg); }
}

.intro {
    display: flex;
    align-items: center;
}

#title {
    font-size: 4rem;
}

#titleName {
    color: #5352ed;
}

#subTitle {
    font-size: 1.7rem;
}

#mugshot {
    border-radius: 50%;
    border: 3px solid #6666667F;
}

#mugshot:hover {
    border: 3px solid #8A5CF5;
    transform: scale(1.03);
    transition: border 0.2s ease-in-out, transform 0.2s ease-in-out;
}

@media screen and (min-width: 900px) {
    .intro {
        flex-direction: row;
        grid-column-gap: 5vw;
    }

    #title:hover {
        animation: wiggle 1s infinite;
        color: #8A5CF5;
        transition: color 1s ease-in-out;
    }

    #mugshot {
        width: 30vw;
        max-width: 512px;
    }
}

@media screen and (max-width: 900px) {
    .intro {
        flex-direction: column;
    }

    #picture {
        order: -1;
    }

    #title {
        text-align: center;
    }

    #mugshot {
        width: 65vw;
        max-width: 512px;
    }
}