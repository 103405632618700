.header {
    background-color: rgba(23, 23, 26, 0.8);
    backdrop-filter: blur(8px);
    border-bottom: 2px solid rgba(102, 102, 102, 0.5);

    grid-column-gap: 64px;
    position: sticky;
    top: 0;
    z-index: 10;
}

.header a:hover {
    text-decoration: none;
}

.headerItems {
    display: flex;
    grid-column-gap: 32px;
    align-items: center;
}

.headerItem:hover {
    transform: scale(1.1);
    transition: transform 0.1s ease-in-out
}

@media screen and (min-width: 900px) {
    .header {
        display: flex;
        padding: 8px 15vw;
    }
}

@media screen and (max-width: 900px) {
    .header {
        padding: 0 5vw 16px;
    }

    .headerItems {
        grid-column-gap: 32px;
    }
}

@media (prefers-color-scheme: light) {
    .header {
        background-color: rgba(255, 255, 255, 0.8);
    }
}